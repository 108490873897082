<template>
<div class = "mt-16">
    <v-card elevation="0" class = "d-flex justify-center mb-10" color="transparent">
      <v-card-title><p class="text-lg-h3 text-h5" style='text-align: center'>{{message}}</p></v-card-title>
    </v-card>
    <v-card class = "ma-auto" :width="$vuetify.breakpoint.mobile ? '90%' : '50%'" color="transparent">
      <v-card-text>
        <v-list>
            <v-list-item>
                <v-list-item-title>
                    <h3>Possible reasons:</h3>
                </v-list-item-title>
            </v-list-item>
            <div v-for="nav in navigations" :key="nav.title">
            <v-list-item  v-ripple @click="nav.action()" v-if="nav.displayed">
                <v-list-item-content>
                <v-list-item-title class="text-headline">{{nav.title}}</v-list-item-title>
                <v-list-item-subtitle>{{nav.sub}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action><v-icon :color="$vuetify.theme.isDark?'white':'black'">
                    mdi-arrow-right-bold-hexagon-outline</v-icon></v-list-item-action>
            </v-list-item>
            </div>
        </v-list>
      </v-card-text>
    </v-card>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
export default {
  data: function () {
    return {
      message: '',
      navigations: [
        {
          title: 'Current Internet',
          sub: 'Check it & Click to Reload',
          action: function () {
            window.location.reload()
          },
          displayed: false
        },
        {
          title: 'Wrong Charity URL',
          sub: 'Go back to the Centre of iocharity',
          action: () => {
            // console.log(this.$router)
            this.$router.push({ name: 'IocharityView' })
          },
          displayed: false
        },
        {
          title: 'You are probably not logged in with the correct access right to this page',
          sub: 'Login',
          action: function () {
            // send user to login page
            // console.log(Vue.$pathConverter.getFirstSegment())
            const uri = Vue.$keycloak.createLoginUrl({
              redirectUri: `${window.location.origin}/${Vue.$pathConverter.getFirstSegment()}`
            })
            // open a new window for login
            const pop = window.open(uri)
            window.addEventListener('message', (e) => {
              if (e.data.auth === true) {
                const url = decodeURIComponent(new URL(window.location.href).searchParams.get('401'))
                pop.close()
                window.location.href = url
              }
            })
          },
          displayed: false
        },
        {
          title: 'Found Your Charity!',
          sub: "Go to the Charity's Home Page",
          action: () => {
            window.location.href = `/${this.charityOfApp.email}`
          },
          // check if the charity of the app is loaded successfully.
          // If not, it means the url may be wrong and thus hide this navigation option
          displayed: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters('appStore', ['charityOfApp'])
  },
  watch: {
    /**
      * update the displayed of third navigation option when there is a change made to the charityOfApp
      * e.g. Data of the charity might have not been loaded when this page is created. When the data is successfully loaded,
      *   this function will reflect the change
      */
    charityOfApp (newValue) {
      // console.log('ErrorView: found a new ', newValue)
      this.navigations[3].displayed =
        newValue && 'email' in newValue
    }
  },
  created () {
    // check if user is in offline mode
    if (!navigator.onLine) {
      this.navigations[0].displayed = true
      this.navigations[0].title = 'Your current Internet Mode: Offline'
    }

    // display the option for navigating back to main page of the charity when url is found correct
    this.navigations[3].displayed = this.charityOfApp && 'email' in this.charityOfApp
    // check whether the security leads to this error page and set error message accordingly
    if (window.location.href.includes('401')) {
      // Due to unauthorized access
      this.message = 'Opps! 401! You do not have the right to access...'
      this.navigations[2].displayed = true
    } else {
      // the page is not found
      this.message = 'Opps! 404! This page is not found...'
      this.navigations[1].displayed = true
    }
  }
}
</script>
